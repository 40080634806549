:root {
  --c-blue: #368bd7;
  --c-blue-dimmed: #dfecff;
  --c-gray: #787878;
  --c-border: #d6d6d6;
  --c-border-dimmed: #f0f0f0;
  --c-disabled: #d7d7d7;
  --c-text: #222222;
  --c-text-dimmed: #b0b0b0;
  --c-text-gray: #5f5f5f;
  --c-red-dimmed: #ffdfdf;
  --c-red: #ED6767;
  --c-green: #12BC37;

  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
}

.App {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
h3 {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid black;
}
