.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  min-height: 0;
  background: rgba(0,0,0, .7);
}

.inner {
  overflow: auto;
  height: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  display: flex;
}

.content {
  margin: auto;
  border-radius: 5px;
  background: #ffffff;
  padding: 15px;
  position: relative;
  animation: moveIn 300ms;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  -webkit-appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
}

.title {
  font-size: 20px;
  margin-bottom: 15px;
}

.closing {
  animation: fadeOut 300ms
}

.closing .modal-content {
  animation: moveOut 300ms
}

@keyframes moveIn {
  0% {
    transform: translateY(30px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
