.symbolInput {
    background: #EFEFEF;
    color: black;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    width: 70px;
    text-transform: uppercase;
}
.symbolInput:focus {
    outline: none !important;
}
.rating11 {
    background: #e0d1aa;
}
.rating10 {
    background: #eadebf;
}
.rating9 {
    background: #f3ede0;
}
.rating1, .rating2, .rating3, .rating0 {
    opacity: 0.3;
}
.rating4 {
    opacity: 0.6;
}
.star10 {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    left: -15px;
    top: -4px;
    color: goldenrod;
    font-size: 20px;
}
.star9 {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    left: -15px;
    top: -4px;
    color: goldenrod;
    font-size: 20px;
}
.strategyInput {
    margin-left: 20px;
    background: #EFEFEF;
    color: black;
    border: none;
    padding: 5px 10px;
    font-size: 16px;
    width: 130px;
}
