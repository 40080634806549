.button {
  display: inline-block
}

.menu {
  max-height: 205px;
  margin-top: 8px;
  width: 200px;
  overflow: auto;
  position: absolute;
  background: #ffffff;
  /*box-shadow: rgba(0, 0, 0, 0.1) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 4px 11px;*/
  z-index: 10000;
  border: 2px solid black;
}


.item {
  cursor: pointer;
  padding: 8px 11px;
  font-size: 14px;
  color: #1d263f;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.item:hover {
  background: rgb(222, 235, 255);
}
