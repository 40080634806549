.loginContainer {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.loginBox {
    width: 240px;
    padding: 20px;
    text-align: center;
    border: 1px solid #0a244d;
}
.form {
    font-size: 18px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: stretch;
}
.form input {
    padding: 7px 20px;
    border: 1px solid #0a244d;
    margin-top: 10px;
    font-size: 15px;
}
.form button {
    margin-top: 10px;
    border: none;
    padding: 5px 30px;
    background: #0a244d;
    color: #e8eaed;
    font-size: 16px;
    -webkit-appearance: none;
}
.error {
    font-size: 14px;
    color: darkred;;
}
hr {
    margin: 20px 0;
}
a {
    color: #0a244d;
}
