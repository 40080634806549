.button {
  line-height: 30px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  justify-items: center;
  align-items: center;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid var(--c-border);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.150s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px 0 13px;
  font-size: 14px;
  border-radius: 15px;
  color: var(--c-text);
  background-color: #ffffff;
}

.button:not(:disabled):hover,
.button:not(:disabled):focus {
  color: var(--c-blue);
  background-color: #ffffff;
  border-color: var(--c-blue);
}

.button:not(:disabled):active {
  color: var(--c-blue);
  background-color: #ffffff;
  border-color: var(--c-blue);
}

.button:disabled {
  opacity: 0.5;
}

.button :global .icon,
.button :global span {
  display: inline-block;
  pointer-events: none;
}

.button :global .icon {
  margin-right: 6px;
  vertical-align: -0.2em;
  color: currentColor;
}


.button :global .icon path {
  fill: currentColor;
}

.button :global .icon :last-child {
  margin-right: 0;
}

.button :global .span > svg {
  shape-rendering: optimizeSpeed
}

.button,
.button:active,
.button:focus {
  outline: 0;
}

/*.button-primary*/
/*  color: #fff*/
/*  background-color: #1890ff*/
/*  border-color: #1890ff*/
/*  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12)*/
/*  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045)*/
/*  &:not(:disabled):hover,*/
/*  &:not(:disabled):focus*/
/*    color: #fff*/
/*    background-color: #40a9ff*/
/*    border-color: #40a9ff*/
/*  &:not(:disabled):active*/
/*    color: #fff*/
/*    background-color: #096dd9*/
/*    border-color: #096dd9*/

/*.button-outline*/
/*  color: #1890ff*/
/*  background-color: #ffffff*/
/*  border-color: #1890ff*/
/*  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12)*/
/*  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045)*/
/*  &:not(:disabled):hover,*/
/*  &:not(:disabled):focus*/
/*    color: #1890ff*/
/*    background-color: #ffffff*/
/*    border-color: #40a9ff*/
/*  &:not(:disabled):active*/
/*    color: #096dd9*/
/*    background-color: #ffffff*/
/*    border-color: #096dd9*/

/*.button-danger*/
/*  color: #fa755a*/
/*  background-color: #ffffff*/
/*  border-color: #fa755a*/
/*  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12)*/
/*  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045)*/
/*  &:not(:disabled):hover,*/
/*  &:not(:disabled):focus*/
/*    color: #fa755a*/
/*    background-color: #ffffff*/
/*    border-color: #fa755a*/
/*  &:not(:disabled):active*/
/*    color: #fa755a*/
/*    background-color: #fa755a*/
/*    border-color: #fa755a*/

/*.button::before*/
/*  position: absolute*/
/*  top: -1px*/
/*  right: -1px*/
/*  bottom: -1px*/
/*  left: -1px*/
/*  z-index: 1*/
/*  display: none*/
/*  background: #fff*/
/*  border-radius: inherit*/
/*  opacity: 0.35*/
/*  transition: opacity 0.15s*/
/*  content: ''*/
/*  pointer-events: none*/
