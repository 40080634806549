.neg {
  color: var(--c-red);
  font-weight: 600;
}
.negSign {
  color: var(--c-red);
  font-weight: 600;
  margin-left: -0.5em;
}
.pos {
  color: var(--c-green);
  font-weight: 600;
}
.posSign {
  color: var(--c-green);
  font-weight: 600;
  margin-left: -0.5em;
}
