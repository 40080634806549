@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');

html, body {
  height: 100%;
}
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Inconsolata', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*@media screen and (prefers-color-scheme: dark) {*/
/*  body {*/
/*    background-color: #202124;*/
/*    color: #e8eaed;*/
/*  }*/
/*}*/

html {
  --text-color-normal: #0a244d;
  --text-color-light: #8cabd9;
}

html[data-theme='dark'] {
  --text-color-normal: hsl(210, 10%, 62%);
  --text-color-light: hsl(210, 15%, 35%);
  --text-color-richer: hsl(210, 50%, 72%);
  --text-color-highlight: hsl(25, 70%, 45%);
}

input {
  border: none;
  padding: 5px;
  font-family: 'Inconsolata', monospace;
  font-weight: 500;
}
hr {
  height: 0;
  background: #202124;
}
