.header {
    width: 100%;
    height: 56px;
    border-bottom: 2px solid black;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 5px;
    font-weight: 500;
    font-size: 18px;
}

.username {
    cursor: pointer;
}
