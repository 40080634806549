/* @todo move to button component */

.button {
  margin-top: 10px;
  border: none;
  padding: 5px 30px;
  background: #0a244d;
  color: #e8eaed;
  font-size: 16px;
  -webkit-appearance: none;
}
